<template>
  <!-- eslint-disable -->
  <div class="w-100">
    <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <filters :filtros="user_role === 'admin-lesson' ? fieldsGeneral : fields" :is-add.sync="isAdd" ref="filters"> </filters>
      <b-card ref="filterContent" no-body class="sticky">
        <b-card-header class="px-2 py-2 pb-0">
          <b-row class="w-100">
            <b-col lg="3" class="mb-lg-0 mb-1">
              <b-form-group label="Ambito" label-for="correo">
                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="tipoAmbito" label="text"
                  input-id="type_ambito" :reduce="(tipoAmbito) => tipoAmbito.value" v-model="type_ambito"
                  @input="searrchDataByFilter" placeholder="Tipo"
                  :disabled="user_role === 'user-lesson' || user_role === 'reviewer-lesson'" />
              </b-form-group>
            </b-col>
            <b-col lg="3" class="mb-lg-0 mb-1" v-if="type_ambito == 'proyecto'">
              <b-form-group label="Proyecto" label-for="name">
                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="proyectos" label="code"
                  input-id="project" :reduce="(proyectos) => proyectos.id" placeholder="Proyecto" class="select-obra"
                  @input="searrchDataByFilter" v-model="project_id"
                  ><template
                    v-slot:selected-option="option">
                    {{ option.code }} - {{ option.description }}
                  </template>
                  <template slot="option" slot-scope="option">
                    {{ option.code }} - {{ option.description }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col lg="3" class="mb-lg-0 mb-1" v-if="type_ambito == 'area'">
              <b-form-group label="Area" label-for="dni">
                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="areas" label="text"
                  input-id="area" :reduce="(areas) => areas.value" placeholder="Area" v-model="area_name"
                  @input="searrchDataByFilter">
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="3" class="pl-0">
              <b-form-group label="Palabra clave en lección aprendida" label-for="project_id" class="mr-2 w-100">
                <b-form-input v-model="keyword" id="correo" placeholder="Palabra clave" autocomplete="off"
                  @input="searrchDataByFilter" />
              </b-form-group>
            </b-col>
            <b-col md="2" class="pl-0">
              <b-form-group label="Estados" label-for="project_id" class="mr-2 w-100">
                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="estadoGeneral" label="text"
                  input-id="status" :reduce="(estados) => estados.value" v-model="status" @input="searrchDataByFilter"
                  placeholder="Estado" />
              </b-form-group>
            </b-col>
            <b-col md="1" class="pl-0">
              <b-form-group label="SSOMA" label-for="ssomac" class="mr-2 w-100">
                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="SSOMACarray" label="text"
                  input-id="ssomac" :reduce="(SSOMACarray) => SSOMACarray.value" :disabled="isUserSSomac" v-model="ssomac" @input="searrchDataByFilter"
                  placeholder="-" />
              </b-form-group>
            </b-col>
            <b-col md="12" class="pl-0">
              <b-button variant="secondary" class="mr-2" @click.prevent="clean">
                <span class="text-nowrap"><feather-icon icon="TrashIcon" size="15" /> Ver Todos</span>
              </b-button>
              <!-- <b-button variant="primary" @click="addRestric(project_id)" class="mr-2">
                <span class="text-nowrap"> <feather-icon icon="PlusCircleIcon" /> Agregar </span>
              </b-button> -->
              <b-button variant="success" @click="exportExcel()" class="mr-2">
                <span class="text-nowrap"> <feather-icon icon="UploadIcon" /> Exportar </span>
              </b-button>
              <b-button @click="showFilters" variant="primary" v-b-toggle="'accordion1'">
                Columnas
                <feather-icon v-if="show" icon="PlusCircleIcon" size="15" />
                <feather-icon v-else icon="MinusCircleIcon" size="15" />
              </b-button>

            </b-col>
          </b-row>
        </b-card-header>
      </b-card>
      <b-card no-body ref="tableCard">
        <div class="table-overflow-lesson">
          <b-table
            class="position-relative"
            empty-text="No existen"
            :fields="user_role === 'admin-lesson' ? visibleFieldsGeneral : visibleFields"
            :hover="true"
            id="__BVID__190"
            :items="records"
            no-border-collapse
            ref="selectableTable"
            :responsive="false"
            show-empty
            @sort-changed="sortChanged"
          >
            <!-- Column: Actions -->
            <template #cell(code)="data">
              <div class="code-column">
                <span>{{ data.item.code }}</span>
              </div>
            </template>
            <template #cell(correlative)="data">
              <div class="correlative-column">
                <span>{{ data.item.correlative }}</span>
              </div>
            </template>
            <template #cell(actions)="data">
              <div>
                <b-button class="btn-size-lesson" @click.prevent="view(data.item)" v-b-tooltip.noninteractive.hover.left="'Vista'"
                  variant="flat-primary">
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <b-button  class="btn-size-lesson" @click.prevent="edit(data.item)" v-b-tooltip.noninteractive.hover.left="'Editar'"
                  variant="flat-success" v-if="data.item.status != 'Oficial'">
                  <feather-icon icon="Edit2Icon" />
                </b-button>
                <b-button  class="btn-size-lesson" v-b-tooltip.noninteractive.hover.left="'Eliminar'" @click="deleteAction(data.item)"
                  variant="flat-danger" v-if="user_role == 'admin-lesson'">
                  <feather-icon icon="Trash2Icon" />
                </b-button>
                <b-button  class="btn-size-lesson" @click.prevent="exportFormat(data.item)" v-b-tooltip.noninteractive.hover.left="'Exportar'"
                  variant="flat-primary">
                  <feather-icon icon="DownloadIcon" />
                </b-button>
                <b-button  class="btn-size-lesson" @click.prevent="showTimeLine(data.item)"
                  v-b-tooltip.noninteractive.hover.left="'Comentarios'" variant="flat-info">
                  <feather-icon icon="MessageCircleIcon" />
                </b-button>
              </div>
            </template>
            <template #cell(register_by)="data">
              <div class="register-by-column">
                <span>{{ data.item.register_by }}</span>
              </div>
            </template>
            <template #cell(title_lesson)="data">
              <span>{{ data.item.title_lesson }}</span>
            </template>
            <template #cell(project.code)="data">
              <div class="project-column">
                <span>{{ data.item.project ? data.item.project.code : ''}}</span>
              </div>
            </template>
            <template #cell(area_name)="data">
                <span class="area-column">{{ nameArea(data.item.area_name) }}</span>
            </template>
            <template #cell(status_extra)="data">
              <div>
                <div v-html="labelStatus(data.item.status)"></div>
              </div>
            </template>
            <template #cell(date_specification)="data">
              <div class="date-class">
                <span>{{ validDate(data.item.date_specification) }}</span>
              </div>
            </template>
            <template #cell(date_last_update)="data">
              <div class="date-class">
                <span>{{ validDate(data.item.date_last_update) }}</span>
              </div>
            </template>
            <template #cell(is_security)="data">
              <span>{{ securityType(data.item.is_security) }}</span>
            </template>
            <template #cell(division)="data">
              <span>{{ nameDivision(data.item.division) }}</span>
            </template>
            <template #cell(type_work)="data">
              <span>{{ nameTypeWork(data.item.type_work) }}</span>
            </template>
          </b-table>
        </div>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col sm="3">
              <b-form-group label-cols="4" label-cols-md="4" label-size="md" label="Entradas" label-for="input-md">
                <b-form-select v-model="showEntrie" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                  :options="entries" @change="changeSizePage" />
              </b-form-group>
            </b-col>
            <b-col sm="3" class="mt-75" style="font-size: 12px">
              <span> {{ totalElements }} Registros en total</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalElements" :per-page="showEntrie"
                class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="cambioPagina" pills>
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="15" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="15" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-modal id="modal-comment" ref="modal-comment" centered title="Comentarios" ok-only hide-footer
        @hide="closeComment">
        <b-card-text class="card_content pb-2">
          <hr />
          <div class="content_main">
            <b-row class="content_form">
              <b-col md="12" :class="{ content_timeline: timeLine.length > 0 }" style="margin-top: 15px;">
                <app-timeline>
                  <app-timeline-item v-for="(item, index) in timeLine" :key="index" variant="primary">
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                      <h6>
                        {{ item.user != null ? item.user.name : 'sistema de lecciones' }}
                      </h6>
                      <small class="text-muted">{{
                        moment(item.created_at).format('yyyy-MM-DD')
                      }}</small>
                    </div>
                    <p>{{ item.description }}</p>
                  </app-timeline-item>
                </app-timeline>
              </b-col>
            </b-row>
          </div>
        </b-card-text>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import LessonService from '@/services/LessonService';
import ProjectsService from '@/services/ProjectsService';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import { required } from '@validations';
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue';
import moment from 'moment';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Vue from 'vue';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import filters from './filters.vue';

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
const APIURL = process.env.APIURLFILE
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      estadoGeneral: [
        {
          text: 'Aprobado',
          value: 'Aprobado'
        },
        {
          text: 'Descartado',
          value: 'Descartado'
        },
        {
          text: 'Documentado',
          value: 'Documentado'
        },
        {
          text: 'Observado',
          value: 'Observado'
        },
        {
          text: 'Oficial',
          value: 'Oficial'
        },
        {
          text: 'Por documentar',
          value: 'Por-documentar'
        },
        {
          text: 'Registrado',
          value: 'Registrado'
        },
      ],
      SSOMACarray: [
      {
          text: 'Sí',
          value: true
        },
        {
          text: 'No',
          value: false
        },
      ],
      keyword: '',
      type_ambito: "",
      required,
      showLoading: false,
      status: '',
      ssomac: false,
      adminSsomac: false,
      area_name: null /* JSON.parse(localStorage.getItem('userData')).role_user[0].area_name */,
      user_role: JSON.parse(localStorage.getItem('userData')).role_user[0].role_lesson,
      show: true,
      timeLine: [],
      title: '',
      comment: '',
      apiurl: APIURL,
      addComent: false,
      arrayFilterProyect: [],
      areas: [
        {
          text: 'Comunicaciones',
          value: 'COM'
        },
        {
          text: 'Tecnologías de las Información',
          value: 'TI'
        },
        {
          text: 'Selección y Desarrollo',
          value: 'SELDESA'
        },
        {
          text: 'Nómina y Relaciones Laborales',
          value: 'NOMRL'
        },
        {
          text: 'Responsabilidad Social y Bienestar',
          value: 'RRSS'
        },
        {
          text: 'Archivo',
          value: 'ARCHIV'
        },
        {
          text: 'Impuestos y Cuentas por Pagar',
          value: 'CXP'
        },
        {
          text: 'Finanzas y Seguros',
          value: 'FINANZAS'
        },
        {
          text: 'Costos y Control de Gestión',
          value: 'COCG'
        },
        {
          text: 'Contabilidad',
          value: 'CONTA'
        },
        {
          text: 'Logística',
          value: 'LOG'
        },
        {
          text: 'Almacén Central y Transporte',
          value: 'ALM'
        },
        {
          text: 'Negocios',
          value: 'NEG'
        },
        {
          text: 'Propuestas',
          value: 'PROP'
        },
        {
          text: 'SSOMA',
          value: 'SSOMAC'
        },
        {
          text: "Unidad de Equipos",
          value: "EQUIP",
        },
        {
          text: "Almacén Chilca",
          value: "ALMCHILCA",
        },
        {
          text: 'Contratos',
          value: 'CONTRATOS'
        },
        {
          text: 'Legal',
          value: 'LEGAL'
        },
        {
          text: 'Planeamiento',
          value: 'PLANEAM'
        }
      ],
      tipoAmbito: [
        {
          text: 'Por Proyecto',
          value: 'proyecto'
        },
        {
          text: 'Por Area',
          value: 'area'
        },

      ],
      divisiones: [
        {
          text: 'Gas y Mineria',
          value: 'gas/mineria'
        },
        {
          text: 'Edificaciones',
          value: 'edificaciones'
        },
        {
          text: 'Infraestructura',
          value: 'infraestructura'
        }
      ],
      fields: [
        { key: "code", label: "Corr.", sortable: false, visible: true, thStyle: { width: '115px' } },
				{ key: "correlative", label: "Codigo", sortable: false, visible: true, thStyle: { width: '120px' } },
        { key: 'register_by', label: 'Registrado Por', sortable: true, visible: true, thStyle: { width: '180px' } },
        { key: 'title_lesson', label: 'Leccion Aprendida', sortable: true, visible: true, thStyle: { width: '205px' } },
        { key: 'date_specification', label: 'Fecha Registro', sortable: true, visible: true, thStyle: { width: '175px' } },
        { key: 'date_last_update', label: 'Fecha de Aprobación', sortable: true, visible: false, thStyle: { width: '175px' } },
        { key: 'ambit', label: 'Ambito', sortable: true, visible: true, thStyle: { width: '105px' }},
        { key: 'project.description', label: 'Proyecto', sortable: true, visible: true, thStyle: { width: '125px' } },
        { key: 'area_name', label: 'Area', sortable: true, visible: true, thStyle: { width: '90px' } },
        { key: 'status', label: 'Estado', sortable: true, visible: true, thStyle: { width: '105px' } },
        { key: 'status_extra', label: '% Avance', sortable: true, visible: true, thStyle: { width: '125px' } },
        { key: 'is_security', label: 'SSOMA', sortable: true, visible: false, thStyle: { width: '100px' } },
        { key: 'email', label: 'Correo', sortable: true, visible: false, thStyle: { width: '100px' } },
        { key: 'division', label: 'Division', sortable: true, visible: false, thStyle: { width: '100px' } },
        { key: 'type_work', label: 'Tipo de Trabajo', sortable: true, visible: false, thStyle: { width: '100px' } },
        { key: 'speciality', label: 'Especialidad', sortable: false, visible: false, thStyle: { width: '150px' } },
        { key: 'typification', label: 'Tipificación', sortable: false, visible: false, thStyle: { width: '150px' } },
        { key: 'typification_others', label: 'Tipificación (Otros)', sortable: false, visible: false, thStyle: { width: '150px' } },
        { key: 'description_impact', label: 'Descripción del Impacto', sortable: false, visible: false, thStyle: { width: '150px' } },
        { key: 'assessment_causes', label: 'Causas Inmediatas', sortable: false, visible: false, thStyle: { width: '150px' } },
        { key: 'impact_mitigation', label: 'Mitigación del Impacto', sortable: false, visible: false, thStyle: { width: '150px' } },
        { key: 'conclusions', label: 'Conclusiones', sortable: false, visible: false, thStyle: { width: '150px' } },
        { key: 'actions', label: 'Acciones', visible: true, thStyle: { width: '200px' } },
      ],
      fieldsGeneral: [
        { key: 'code', label: 'Corre.', sortable: false, visible: true, thStyle: { width: '115px' } },
        { key: 'correlative', label: 'Codigo', sortable: false, visible: true, thStyle: { width: '120px' } },
        { key: 'register_by', label: 'Registrado Por', sortable: true, visible: true, thStyle: { width: '180px' } },
        { key: 'title_lesson', label: 'Leccion Aprendida', sortable: true, visible: true, thStyle: { width: '205px' } },
        { key: 'date_specification', label: 'Fecha Registro', sortable: true, visible: true, thStyle: { width: '140px' } },
        { key: 'date_last_update', label: 'Fecha de Aprobación', sortable: true, visible: false, thStyle: { width: '250px' } },
        { key: 'ambit', label: 'Ambito', sortable: true, visible: true, thStyle: { width: '105px' } },
        { key: 'project.description', label: 'Proyecto', sortable: true, visible: true, thStyle: { width: '125px' } },
        { key: 'area_name', label: 'Area', sortable: true, visible: true, thStyle: { width: '90px' } },
        { key: 'status', label: 'Estado', sortable: true, visible: true, thStyle: { width: '105px' } },
        { key: 'status_extra', label: '% Avance', sortable: true, visible: true, thStyle: { width: '125px' } },
        { key: 'is_security', label: 'SSOMA', sortable: true, visible: false, thStyle: { width: '108px' } },
        { key: 'email', label: 'Correo', sortable: true, visible: false, thStyle: { width: '100px' } },
        { key: 'division', label: 'Division', sortable: true, visible: false, thStyle: { width: '100px' } },
        { key: 'type_work', label: 'Título', sortable: true, visible: false, thStyle: { width: '100px' } },
        { key: 'speciality', label: 'Especialidad', sortable: false, visible: false, thStyle: { width: '150px' } },
        { key: 'typification', label: 'Tipificación', sortable: false, visible: false, thStyle: { width: '150px' } },
        { key: 'typification_others', label: 'Tipificación (Otros)', sortable: false, visible: false, thStyle: { width: '150px' } },
        { key: 'description_impact', label: 'Descripción del Impacto', sortable: false, visible: false, thStyle: { width: '150px' } },
        { key: 'assessment_causes', label: 'Causas Inmediatas', sortable: false, visible: false, thStyle: { width: '150px' } },
        { key: 'impact_mitigation', label: 'Mitigación del Impacto', sortable: false, visible: false, thStyle: { width: '150px' } },
        { key: 'conclusions', label: 'Conclusiones', sortable: false, visible: false, thStyle: { width: '150px' } },
        { key: 'actions', label: 'Acciones', visible: true, thStyle: { width: '200px' } },
      ],
      userData: JSON.parse(localStorage.getItem('userData')),
      categorys: [
        {
          text: 'Obras de Saneamiento',
          value: 'saneamiento'
        },
        {
          text: 'Puentes',
          value: 'puentes'
        },
        {
          text: 'Intercambios viales y pistas',
          value: 'viales-pistas'
        },
        {
          text: 'Aeropuertos',
          value: 'aeropuertos'
        },
        {
          text: 'Obras subterráneas',
          value: 'obras-subterraneas'
        },
        {
          text: 'Obras hidráulicas',
          value: 'obras-hidraulicas'
        },
        {
          text: 'Obras Portuarias',
          value: 'obras-portuarias'
        },
        {
          text: 'Edificaciones Industriales',
          value: 'edificaciones-industriales'
        },
        {
          text: 'Carreteras',
          value: 'carreteras'
        },
        {
          text: 'Vivienda',
          value: 'vivienda'
        },
        {
          text: 'Centros Comerciales',
          value: 'centros-comerciales'
        },
        {
          text: 'Oficinas',
          value: 'oficinas'
        },
        {
          text: 'Hoteles',
          value: 'hoteles'
        },
        {
          text: 'Talleres y almacenes',
          value: 'talleres-almacenes'
        },
        {
          text: 'Lineas de Conducción',
          value: 'lineas-conduccion'
        },
        {
          text: 'Obras Civiles',
          value: 'obras-civiles'
        },
        {
          text: 'PADs',
          value: 'pads'
        },
        {
          text: 'Movimiento de Tierras',
          value: 'mov-tierras'
        },
        {
          text: 'Desarrollo y explotación subterráneo',
          value: 'de-subterraneo'
        },
        {
          text: 'Desarrollo y explotación T. abierto',
          value: 'de-abierto'
        }
      ],
      date_start: new Date(),
      date_end: new Date(),
      estados: [
        {
          text: 'Documentado',
          value: 'Documentado'
        },
        {
          text: 'Registrado',
          value: 'Registrado'
        },
        {
          text: 'Observado',
          value: 'Observado'
        }
      ],
      project_id: JSON.parse(localStorage.getItem('userData')).role_user[0].project_id,
      role_lesson: JSON.parse(localStorage.getItem("userData")).role_user[0].role_lesson,
      restricciones: [],
      restriction_id: null,
      records: [],
      proyectos: [],
      documentSelect: [],
      arrayFilters: [],
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 10,
      totalElements: 0,
      id: 0,
      sort: 'id',
      order: 'desc',
      isAdd: false,
      roles: JSON.parse(localStorage.getItem('userData')).role_user[0],
      areasAssigned: JSON.parse(localStorage.getItem("userData")).user_lesson_area.map(item => item.area_code),
      projectsAssigned: JSON.parse(localStorage.getItem("userData")).user_lesson_project.map(item => item.project_id),
      isUserSSomac: JSON.parse(localStorage.getItem("userData")).role_user[0].is_security,
      navbar: null,
      filterContent: null,
      tableCard: null,
      tableContainer: null,
      selectableTable: null,
      tableHead: null,
      ths: null,
      trs: null,
    }
  },
  components: {
    vSelect,
    filters,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppCollapseItem,
    AppCollapse,
    AppTimelineItem
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible)
    },
    visibleFieldsGeneral() {
      return this.fieldsGeneral.filter((field) => field.visible)
    },

  },
  mounted() {
   // const projectId = localStorage.getItem('project_id');

    //const userId = JSON.parse(localStorage.getItem('userData')).id
    if(this.role_lesson != 'admin-lesson' && this.areasAssigned.length > 0){
      this.areas = this.areas.filter((item) => 
        this.areasAssigned.includes(item.value)
      )
    }
    this.type_ambito = JSON.parse(localStorage.getItem('userData')).role_user[0].area_name != '' && JSON.parse(localStorage.getItem('userData')).role_user[0].area_name != null ? 'area' : 'proyecto'
    this.adminSsomac = (JSON.parse(localStorage.getItem('userData')).role_user[0].role_lesson == 'admin-lesson' && JSON.parse(localStorage.getItem('userData')).role_user[0].is_security)? true : false
    if(this.isUserSSomac){
      this.ssomac = true
    }
    this.searrchDataByFilter()
    this.getSelect()
    this.navbar = document.querySelector(".navbar");
    this.filterContent = this.$refs.filterContent;
    this.tableContainer = this.$el.querySelector(".table-overflow-lesson");
    this.tableCard = this.$refs.tableCard;
    this.selectableTable = this.$refs.selectableTable.$el;
    this.tableHead = this.selectableTable.querySelector("thead");
    this.ths = this.selectableTable.querySelector('thead').querySelectorAll('th');

    this.setupScrollSync();
    new ResizeObserver(this.fixedElements).observe(this.tableCard);
  },
  watch: {
    records(newVal, oldVal) {
      this.$nextTick(() => {
        this.trs = this.selectableTable.querySelector('tbody').querySelectorAll('tr');
        this.fixedElements()
      })
    },
    visibleFields(newVal, oldVal) {
      this.$nextTick(() => {
        this.ths = this.selectableTable.querySelector('thead').querySelectorAll('th');
        this.fixedElements()
      })
    },
    visibleFieldsGeneral(newVal, oldVal) {
      this.$nextTick(() => {
        this.ths = this.selectableTable.querySelector('thead').querySelectorAll('th');
        this.fixedElements()
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleWindowScroll);
    window.removeEventListener("resize", this.fixedElements);
  },
  methods: {
    fixedElements() {
    
      if (!this.trs[0].classList.contains('b-table-empty-row')) {
        const thsTotalWidth = [...this.ths].reduce((acc, th) => acc + th.offsetWidth, 0);

        if (thsTotalWidth > this.tableCard.offsetWidth) {
          this.ths.forEach((th, index) => {
            th.style.flex = "0 0 " + th.offsetWidth + "px";
          });
        } else {
          this.ths.forEach((th, index) => {
            th.style.flex = "1 1 " + th.offsetWidth + "px";
          });
        }

        this.trs.forEach((tr, index) => {
          const tds = tr.querySelectorAll('td');

          this.ths.forEach((th, index) => {
            tds[index].style.width = th.offsetWidth + "px";

            if (thsTotalWidth > this.tableCard.offsetWidth) {
              tds[index].style.flex = "0 0 " + th.offsetWidth + "px";
            } else {
              tds[index].style.flex = "1 1 " + th.offsetWidth + "px";
            }
          });
        });
        
      } else {
        this.selectableTable.style.width = this.tableHead.querySelector('tr').offsetWidth + 1 + "px";
      }

      this.tableHead.style.width = this.tableCard.offsetWidth - 1 + "px";
      this.selectableTable.style.paddingTop = this.tableHead.offsetHeight + "px";
    },
    setupScrollSync() {
      this.tableHead.addEventListener("scroll", () => {
        this.tableContainer.scrollLeft = this.tableHead.scrollLeft;
        this.tableHead.style.transform = `translateX(${this.tableHead.scrollLeft}px)`;
      });

      this.tableContainer.addEventListener("scroll", () => {
        this.tableHead.scrollLeft = this.tableContainer.scrollLeft;
        this.tableHead.style.transform = `translateX(${this.tableHead.scrollLeft}px)`;
      });

      window.addEventListener("scroll", this.handleWindowScroll);
      window.addEventListener("resize", this.fixedElements);
    },
    handleWindowScroll() {
      this.filterContent.style.top = this.navbar.offsetHeight + "px";

      if (this.tableCard.offsetTop - this.navbar.offsetHeight - 7 - window.scrollY <= 0) {
        this.tableHead.classList.add("fixed");
        this.tableHead.style.top = this.navbar.offsetHeight + this.filterContent.offsetHeight + "px";
      } else {
        this.tableHead.classList.remove("fixed");
        this.tableHead.style.top = null;
      }
    },
    closeComment() {
      console.log('close')
    },
    showTimeLine(item) {
      console.log(item)
      this.$refs['modal-comment'].show()
      this.getTimeLine(item.id)
    },
    async getTimeLine(id) {
      const resp = await LessonService.getCommentsByid(id, this.$store)
      console.log({ resp })
      if (resp.status) {
        this.timeLine = resp.data

      }
    },
    labelStatus(status) {
      let btn = ""
      switch (status) {
        case 'Por-documentar':
          btn = `25%`
          break;
        case 'Documentado':
          btn = `50%`
          break;
        case 'Aprobado':
          btn = `75%`
          break;
        case 'Oficial':
          btn = `100%`
          break;
        default:
          btn = `0%`
          break;
      }
      return btn
    },
    async exportFormat(item) {
      let id = item.id;
      if (id != null) {
        this.showLoading = true
        var fileLink = document.createElement('a')
        fileLink.href = this.apiurl + '/lesson/download/' + id
        fileLink.setAttribute('target', '_blank')
        document.body.appendChild(fileLink)
        fileLink.click()

        this.showLoading = false
      } else {
        this.$swal({
          icon: 'warning',
          title: 'Proyecto',
          text: 'Seleccione un proyecto para exportar los datos.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    },
    searrchDataByFilter() {

      this.arrayFilters = []

      if (this.keyword != '' && this.keyword != null) {
        this.arrayFilters.push({
          keyContains: 'title_lesson',
          key: 'contains',
          value: this.keyword
        })
      }

      if (this.type_ambito == 'area') {

        if (this.area_name) {
          this.arrayFilters.push({ keyContains: 'area_name', key: 'equals', value: this.area_name })
        } else {
          this.arrayFilters.push({ keyContains: 'ambit', key: 'equals', value: 'oficina' })
        }
      } else {
        if (this.project_id) {
          this.arrayFilters.push({ keyContains: 'project_id', key: 'equals', value: this.project_id })
          this.arrayFilters.push({ keyContains: 'ambit', key: 'equals', value: 'proyecto' })
        } else {
          this.arrayFilters.push({ keyContains: 'ambit', key: 'equals', value: 'proyecto' })
        }

      }
      if (this.status) {
        this.arrayFilters.push({ keyContains: 'status', key: 'equals', value: this.status })
      }
      if (this.ssomac != null) {
        this.arrayFilters.push({ keyContains: 'is_security', key: 'equals', value: this.ssomac })
      }

      if (this.arrayFilters.length > 0) {
       
        this.getData()
      }
    },
    // selectProyect(item) {

    //   this.arrayFilters = []
    //   if (item != null) {
    //     this.arrayFilters.push({ keyContains: 'project_id', key: 'equals', value: item })
    //   }

    //   this.getData()
    // },   
    securityType(name) {
      if (name) {
        return 'Sí'
      }
      return 'No'
    },
    nameDivision(name) {
      const resp = this.divisiones.filter((e) => e.value == name)
      if (resp.length) {
        return resp[0].text
      }
      return ''
    },
    nameTypeWork(name) {
      const resp = this.categorys.filter((e) => e.value == name)
      if (resp.length) {
        return resp[0].text
      }
      return name
    },
    nameArea(name) {
      const resp = this.areas.filter((e) => e.value == name)
      if (resp.length) {
        return resp[0].text
      }
      return ''
    },
    showFilters() {
      this.isAdd = true
    },
    importData() {
      this.$router.push('/restricciones/importar-datos')
    },
    cambioPagina(e) {
      this.currentPage = e
      this.getData()
    },
    changeSizePage() {
      this.getData()
    },
    sortChanged(data) {
      this.sort = data.sortBy
      if (data.sortDesc) {
        this.order = 'desc'
      } else this.order = 'asc'
    },
    addRestric(id) {
      this.$router.push('/lecciones/registro')
    },
    async exportExcel(id) {
      this.showLoading = true

      var fileLink = document.createElement('a')
      let arrayExcel = [];
      for (let index = 0; index < this.records.length; index++) {
        arrayExcel.push({ keyContains: 'id', key: 'equals', value: this.records[index].id })
      }
      console.log("ARRAYy", JSON.stringify(arrayExcel))
      fileLink.href = this.apiurl + '/lesson/download/now' + '?filter=' + JSON.stringify(arrayExcel)
      console.log("FILELINK", fileLink.href);
      fileLink.setAttribute('target', '_blank')
      document.body.appendChild(fileLink)
      fileLink.click()

      this.showLoading = false
    },
    validDate(fecha) {
      if (fecha != null) {
        const year = new Date(fecha).getFullYear()
        if (year <= 1970) {
          return ''
        }
        return moment(fecha, 'yyyy-MM-DD HH:mm').utc(fecha).format('yyyy-MM-DD')
      }
      return ''
    },
    async getSelect() {
      this.arrayFilterProyect.push({
        keyContains: 'show_lesson',
        key: 'equals',
        value: true
      })
      console.log("this.role_lesson",this.role_lesson)
      console.log("this.projectsAssigned",this.projectsAssigned)
      if(this.role_lesson != 'admin-lesson' && this.projectsAssigned.length > 0){
          this.arrayFilterProyect.push({
          keyContains: "id",
          key: "in",
          value: JSON.stringify(this.projectsAssigned),
        });
      }
      
      console.log("FILTER",this.arrayFilterProyect)
      const url =
        `?limit=10000&filter=`+JSON.stringify(this.arrayFilterProyect)
      
      const respProyectos = await ProjectsService.getProyectosAll(url, this.$store)
      console.log({ respProyectos })
      if (respProyectos) {
        this.proyectos = respProyectos.data.rows
      }
    },
    async getData() {
      this.showLoading = true
      if(this.role_lesson != 'admin-lesson'){
        if(this.projectsAssigned.length > 0){
          this.arrayFilters.push({
            keyContains: "project_id",
            key: "in",
            value: JSON.stringify(this.projectsAssigned),
          });
        }
        if(this.areasAssigned.length > 0 && this.area_name == null){
          this.arrayFilters.push({
            keyContains: "area_name",
            key: "in",
            value: JSON.stringify(this.areasAssigned),
          });
        }
      }
      const url =
        `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}&filter=` +
        JSON.stringify(this.arrayFilters)
        console.log("URL",url)

        console.log("this.arrayFilters", this.arrayFilters)
      const resp = await LessonService.getLessons(url, this.$store)
      console.log({ respLesson:resp })
      if (resp.status) {

        this.records = resp.data.rows
        this.totalElements = resp.data.responseFilter.total_rows
      }
      this.showLoading = false
    },
    edit(item) {

      this.$router.push('/lecciones/editar/' + item.id)
    },
    view(item) {

      this.$router.push('/lecciones/vista/' + item.id)
    },
    async deleteAction(data) {
      this.$swal({
        title: '¿Desea eliminar esta lección?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.deleteData(data.id)
        }
      })
    },
    async deleteData(id) {
      const resp = await LessonService.deleteLesson(id, this.$store)
      if (resp.status) {
        this.currentPage = 1
        this.$swal({
          icon: 'success',
          title: 'Eliminado!',
          text: 'La lección ha sido eliminado.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
        this.getData()
      } else {
        this.$swal({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al eliminar la lección seleccionada.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.content_main {
  .content_timeline {
    max-height: 450px;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }

  .content_form {
    display: flex;
    justify-content: center;
  }
}

.shortcut-buttons-flatpickr-buttons {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.shortcut-buttons-flatpickr-button {
  background-color: rgb(115, 103, 240);
  border: 0px;
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
}

.shortcut-buttons-flatpickr-button:hover {
  background-color: rgb(129, 119, 230);
}

.shortcut-buttons-flatpickr-buttons {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.shortcut-buttons-flatpickr-button {
  background-color: rgb(115, 103, 240);
  border: 0px;
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
}

.shortcut-buttons-flatpickr-button:hover {
  background-color: rgb(129, 119, 230);
}

.code-column {
  width: 50px !important;
}

.correlative-column {
  width: 50px !important;
}

.register-by-column {
  width: 70px;
}


.date-class {
  width: 77px;
}

.title-column {
  width: 120px;
}

.project-column {
  width: 50px;
}

.area-column {
  width: 70px;
  text-wrap: wrap;
}

.vs__dropdown-toggle {
  overflow: hidden !important;
  flex-flow: nowrap !important;
  text-wrap: nowrap !important;
  height: 40px !important;
}
.btn-size-lesson{
  padding: 0.3rem 0.5rem !important;
}
.large-table-fake-top-scroll-container-3 {
  overflow-x: auto;
  overflow-y: hidden;
}

.large-table-fake-top-scroll-container-3.overflow-y-scroll {
  overflow-y: scroll;
}

.large-table-fake-top-scroll-container-3 div {
  font-size: 1px;
  line-height: 1px;
}

.sticky {
  position: sticky;
  z-index: 3;
}

.table-overflow-lesson {
  overflow-x: auto;
  width: 100%;

  thead {
    display: flex;
    overflow-x: auto;
    position: absolute;
    top: 0;

    &.fixed {
      position: fixed;
      transform: translateX(0px) !important;
      z-index: 2;
    }

    tr {
      display: flex;
      flex: 1 1 auto;;

      th {
        flex: 0 0 auto;
        padding: 0.72rem 2rem !important;
        padding-right : 10px !important;
		    font-size:11px !important;
      }
    }
  }

  tbody {
    overflow-x: auto;

    tr {
      display: flex;

      &.b-table-empty-row {
        td {
          flex: 0 0 100%;
        }
      }

      td {
        font-size:11px !important;
		    padding-right : 10px !important;
        flex: 0 0 auto;
      }
    }
  }
}
</style>
